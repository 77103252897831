.grid-hero {
  padding-bottom: 2rem;
  padding-top: 5rem;
}

.grid-hero h1 {
  color: #3b4351;
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 1.5rem;
}

.grid-hero h2 {
  font-size: .9rem;
}

.grid-hero h2 {
  font-size: .9rem;
  font-weight: 400;
  line-height: 1.5rem;
  margin-bottom: 1.5rem;
}

.grid-hero .cards {
  padding-top: 3rem;
}

.grid-hero .card {
  background: #fff;
  border: none;
  border-radius: .1rem;
  padding: 0;
}

.grid-hero .card .card-title {
  color: #2e5bec;
  font-size: 1rem;
  font-weight: 700;
  margin-bottom: 0;
}

.card .card-body, .card .card-footer, .card .card-header {
  padding: .8rem;
  padding-bottom: 0;
}
